import style from "../projects.module.css";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef, useState, useEffect } from "react";

const Project = ({ project }) => {
    const ref = useRef();
    const { scrollYProgress } = useScroll({
        target: ref,
    });

    // Estado para el tamaño de la ventana
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            // Cambiar el estado en función del tamaño de la ventana
            setIsMobile(window.innerWidth <= 768);
        };

        // Ejecutar la función al cargar y cuando cambia el tamaño de la ventana
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Solo aplicar el transform si no es en mobile
    const y = useTransform(scrollYProgress, [0, 1], isMobile ? [0, 0] : [-200, 200]);

    const handleClick = () => {
        window.open(project.link);
    }

    return (
        <section key={project.id} className={style.ProjectBanner}>
            <div className={style.ProjectImage} ref={ref}>
                <img src={project.image} alt="project" onClick={handleClick}/>
            </div>
            <motion.div className={style.ProjectText} style={{ y }}>
                <h1>{project.name}</h1>
                <p>{project.description}</p>
                <button onClick={handleClick}>See More</button>
            </motion.div>
        </section>
    );
}

export default Project;