import style from './contact.module.css';
import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import Email from './Email';
import { Toaster, toast } from "sonner";


const Contact = () =>
{
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const variants = {
        initial: {
            y: 500,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                staggerChildren: 0.1
            }
        }
    }

    const form = useRef(null);
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        message: "",
    });

    const clearForm = () =>
    {
        setFormData({
            full_name: "",
            email: "",
            message: "",
        });
        setEmail('');
        setName('');
        setMessage('');
    }

    const handleSubmit = (e) =>
    {
        e.preventDefault();

        const htmlTemplate = Email(formData);

        const templateParams = {
            full_name: formData.full_name,
            email: formData.email,
            message: formData.message,
            html_template: htmlTemplate,
        };

        emailjs.send("service_2zuy5li", "template_gx3016g", templateParams, "Soj9Q_ZfY4thVEP4L")
            .then((response) =>
            {
                console.log('SUCCESS!', response.status, response.text);
                toast.success("Tu solicitud de contacto ha sido enviada con éxito. Nos pondremos en contacto contigo lo más pronto posible.");
                clearForm();
            })
            .catch((error) => console.log(error));
    }

    return (
        <motion.section id="contact" className={style.Contact} variants={variants} initial="initial" whileInView="animate">
            <motion.div className={style.ContactText} variants={variants}>
                <motion.h1 variants={variants}>Let´s work together</motion.h1>
                <motion.p variants={variants}>Have a project you´d like to discuss? I´d love to hear from you. Fill out the form and I´ll get back to you within 24 hours.</motion.p>
                <motion.div className={style.ContactInfo} variants={variants}>
                    <motion.h3 variants={variants}>Phone</motion.h3>
                    <motion.p variants={variants}>+57 317 431 9914</motion.p>
                </motion.div>
                <motion.div className={style.ContactInfo} variants={variants}>
                    <motion.h3 variants={variants}>Email</motion.h3>
                    <motion.p variants={variants}>info@gonzalezlozada.com</motion.p>
                </motion.div>
                <motion.div className={style.ContactInfo} variants={variants}>
                    <motion.h3 variants={variants}>Address</motion.h3>
                    <motion.p variants={variants}>Bogotá, Colombia</motion.p>
                </motion.div>
            </motion.div>
            <motion.form className={style.ContactForm} variants={variants} ref={form} onSubmit={handleSubmit}>
                <motion.input type="text" placeholder="Name" required value={name} onChange={(e) => setName(e.target.value)} variants={variants}/>
                <motion.input type="email" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)} variants={variants}/>
                <motion.textarea placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}  variants={variants}/>
                <Toaster
                    richColors
                    duration={2000}
                    position="top-right"
                />
                <motion.button type="submit" variants={variants}>Send</motion.button>
            </motion.form>
        </motion.section>
    )
}

export default Contact;