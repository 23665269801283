const Email = (data) =>
{
    return (`
        <html lang="es">
        <head>
          <title>Contact Request</title>
        </head>
        <body>
          <h1>Solicitud de Contacto</h1>
          <p><strong>Nombre Completo:</strong> ${data.full_name}</p>
          <p><strong>Email:</strong> ${data.email}</p>
          <p><strong>Mensaje:</strong> ${data.message}</p>
        </body>
        </html>
    `);
};

export default Email;
