import styles from "../sidebar.module.css";
import { motion } from "framer-motion";


const Links = () =>
{
    const items = [
        "home",
        "services",
        "projects",
        "contact"
    ]

    const variants = {
        open: {
            transition: {
                staggerChildren: 0.1
            },
        },
        closed: {
            transition: {
                staggerChildren: 0.05,
                staggerDirection: -1
            },
        }
    }

    const itemVariants = {
        open: {
            y: 0,
            opacity: 1,
        },
        closed: {
            y: 50,
            opacity: 0,
        }
    }

    return (
        <motion.div className={styles.Links} variants={variants}>
            {items.map((item) => (
                <motion.a href={`#${item}`} key={item} variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.8}}>
                    {item}
                </motion.a>
            ))}
        </motion.div>
    );
}

export default Links;