import styles from "./sidebar.module.css";
import Links from "./links/Links";
import Toggle from "./toggle/Toggle";
import { useState } from "react";
import { motion } from "framer-motion";

const Sidebar = () =>
{
    const [open, setOpen] = useState(false);

    const variants = {
        open: {
            clipPath: "circle(1200px at 50px 50px)",
            transition: {
                type: "spring",
                stiffness: 20
            }
        },
        closed: {
            clipPath: "circle(30px at 50px 50px)",
            transition: {
                delay: 0.1,
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        }
    }

    return (
        <motion.div className={styles.Sidebar} animate={open ? "open" : "closed"}>
            <motion.div className={styles.Panel} variants={variants}>
                <Links />
            </motion.div>
            <Toggle setOpen={setOpen}/>
        </motion.div>
    );
}

export default Sidebar;